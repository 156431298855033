const Navbar = () => {
	return (
		<>
			<div className="py-3 w-11/12 md:py-6 md:text-xl flex justify-between md:justify-end md:gap-4 text-white mx-auto md:mr-16">
				<a href="#projects">
					<div className="hover:text-black cursor-pointer">Projects</div>
				</a>
				<a
					href="https://drive.google.com/file/d/1PjaNR8K034wn_KNcQKF9vSbBSz_FYPd7/view?usp=sharing"
					target="_blank"
					rel="noreferrer">
					<div className="hover:text-black cursor-pointer">Resume</div>
				</a>
				<a
					href="https://www.linkedin.com/in/avikaldarolia/"
					target="_blank"
					rel="noreferrer">
					<div className="hover:text-black cursor-pointer">LinkedIn</div>
				</a>
				<a
					href="https://github.com/avikaldarolia"
					target="_blank"
					rel="noreferrer">
					<div className="hover:text-black cursor-pointer">Github</div>
				</a>
				<a
					href="https://leetcode.com/u/avikaldarolia7/"
					target="_blank"
					rel="noreferrer">
					<div className="hover:text-black cursor-pointer">LeetCode</div>
				</a>
			</div>
		</>
	);
};

export default Navbar;
